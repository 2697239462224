import "./index.css";
import React, { useState, useEffect } from "react";
import { Button, Toast, Image, Mask } from "antd-mobile";
import { Input, message, Spin, Modal } from "antd";
// import Marquee from "react-fast-marquee";
// const logo = require("../../page/Charge/assets/Header/icons.png");
// const leftText = require("../../page/Charge/assets/Header/text.png");
// const RightImg = require("../../page/Charge/assets/Header/RightDownload.png");
// const pathLeft = require("../../page/Charge/assets/Header/path.png");
// const vertorRight = require("../../page/Charge/assets/Header/Vector.png");
const closeModel = require("./assets/closeModel.png");
const DownloadHeader = React.forwardRef((props, ref) => {
  //提示框
  const [isModalOpen, setIsModalOpen] = useState(true);
  //关闭提示框v
  const handleOk = () => {
    setIsModalOpen(false);
  };
  //打开提示框
  const opens = () => {
    setIsModalOpen(true);
  };
  console.log("0909==++==");

  const downloadApp = () => {
    window.location.href = "https://quanquan.ningmengxinxi.cn";
  };
  return (
    <div>
      <Modal
        title=""
        visible={isModalOpen}
        onOk={handleOk}
        okText={"知道了"}
        closeIcon={" "}
        // style={{
        //   top: 0,
        // }}
        style={props.types == "withdraw" ? { top: "20%" } : { top: "10%" }}
        footer={
          [
            <div className="close_box">
              <Image
                className="closeImg"
                src={closeModel}
                width={180}
                height={40}
                onClick={handleOk}
              />
            </div>,
          ] // 设置footer为空，去掉 取消 确定默认按钮
        }
      >
        <div className="title_text">公告</div>
        {props.types == "withdraw" ? (
          <div className="model_text" style={{ marginTop: "14px" }}>
            平台所设的互动礼物道具不可反向兑换成现金及其他有价值的财物，平台禁止并严厉打击任何形式的线下交易，严厉打击“套现”、“返现”等违规行为;
          </div>
        ) : (
          <div>
            <div className="model_text" style={{ marginTop: "14px" }}>
              1、本充值中心为圈圈开黑官方充值平台请勿相信任何第三方个人/组织/渠道传播的代充、优惠、返利等消息;
            </div>
            <div className="model_text">
              2、请妥善保管好自己的账号密码或支付密码等信息,谨防诈骗;
            </div>
            <div className="model_text">3、严禁未成年人充值消费;</div>
            <div className="model_text" style={{ color: "red" }}>
              4、谨防微信/QQ/TT等其他平台人员要求代充，极有可能是诈骗;
            </div>
            <div className="model_text">
              5、严禁任何不正当方式诱导打赏、赌博、诈骗等行为。如有发现,请及时联系客服进行举报;
            </div>
            <div className="model_text">
              6、如有任何异常现象或问题,请前往APP内咨询在线客服。
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
});
export default DownloadHeader;
